.container {
    width: 100%;
    height: 500px;
    margin: 2em auto;
    overflow: hidden;
    position: relative;
}

.slider {
    position: relative;
    height: auto;
    animation: slider 15s linear infinite;
}

.slider:hover {
    animation-play-state: paused;
}

@keyframes slider {
    0%   { top:   10em }
    100% { top: -16em }
}

.blur .slider {
  	margin: 0;
    padding: 0 1em;
    line-height: 1.5em;
}

.blur:before, .blur::before,
.blur:after,  .blur::after {
    left: 0;
    z-index: 1;
    content: '';
    position: relative;
    width: 100%; 
    height: 2em;
}

.blur:after, .blur::after {
    bottom: 0;
    transform: rotate(360deg);
}

.blur:before, .blur::before {
    top: 10em;
}
